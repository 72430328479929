<!--
 * @Author       : JiangChao
 * @Date         : 2024-07-29 14:37:04
 * @LastEditors  : JiangChao
 * @LastEditTime : 2024-11-03 11:33:00
 * @Description  : 预览报表
-->
<template>
  <el-dialog
    :visible.sync="dialogFormVisible"
    width="50%"
    :append-to-body="true"
  >
    <template slot="title">
      <div class="font-heavy" style="font-size: 18px;color: #303133">
        {{ $t("show_list") }}
      </div>
    </template>
    <!-- 表格 -->
    <el-table
      :data="list"
      style="width: 100%"
      border
      :header-cell-style="{
        background: '#f6f6fa',
        textAlign: 'center',
        color: 'black',
      }"
      :cell-style="{ 'text-align': 'center' }"
      :empty-text="$t('no_data')"
    >
      <el-table-column :label="$t('date')" prop="showDate"> </el-table-column>
      <el-table-column :label="$t('temperature')" prop="temp">
        <template v-slot:default="scope">
          <span>
            {{ Number(scope.row.temp) | convertTemperature(setSelectedTempType)
            }}{{ setSelectedTempType }}
          </span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('humidity')" prop="wetness">
        <template v-slot:default="scope">
          <span> {{ scope.row.wetness || "-" }}%</span>
        </template>
      </el-table-column>
    </el-table>

    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">{{
        $t("cancel")
      }}</el-button>
      <el-button type="primary" @click="dialogFormVisible = false">{{
        $t("confirm")
      }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "ShowListDialog",
  data() {
    return {
      dialogFormVisible: false,
      list: [],
    };
  },
  methods: {
    showDialog(list) {
      this.list = list;
      this.dialogFormVisible = true;
    },
  },
  props: {},
  components: {},
  computed: {
    setSelectedTempType() {
      return this.$store.getters.selectedTempType;
    },
    setSelectedLanguage() {
      return this.$store.getters.selectedLanguage;
    },
  },
  watch: {
    setSelectedLanguage(newVal) {
      this.$i18n.locale = newVal;
    },
  },

  mounted() {
    this.$i18n.locale = this.setSelectedLanguage;
  },
  created() {},
};
</script>

<style scoped lang="less"></style>
